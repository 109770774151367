
  import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";
  import { PropertyType, PropertyHouseSubTypes, PropertyAppartmentSubTypes, PropertyCommercialSubTypes, PropertyGroundSubTypes, PropertyParkingSubTypes } from "@/enums/property";

  @Component
  export default class PagePropertyPartDesktopSubtypes extends Mixins(HasDesktopPart) {
    @Prop({ default: null }) value!: PropertyHouseSubTypes | PropertyAppartmentSubTypes | PropertyCommercialSubTypes | PropertyGroundSubTypes | PropertyParkingSubTypes | null;

    @Prop({ required: true }) type!: PropertyType | null;

    get subtypes() {
      switch (this.type) {
        case PropertyType.House:
          return PropertyHouseSubTypes;
        case PropertyType.Appartment:
          return PropertyAppartmentSubTypes;
        case PropertyType.Land:
          return PropertyGroundSubTypes;
        case PropertyType.Commercial:
          return PropertyCommercialSubTypes;
        case PropertyType.Parking:
          return PropertyParkingSubTypes;
      }

      return [];
    }

    get selectOptions() {
      return Object.values(this.subtypes).map((v) => {
        return {
          label: this.$t(`property.subtypes.${v}`),
          value: v,
        };
      });
    }

    @Watch("type")
    handleTypeChange(type: PropertyType) {
      this.localValue = null;
      this.handleInput();
    }
  }
